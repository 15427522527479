.filterBTN {
  color: #001c41;
  margin-right: 30px;
}

.filterBTN:hover {
  cursor: pointer;
}

.patient {
  width: 25%;
  text-align: start;
  color: #001c41;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.treatment {
  width: 12%;
  text-align: center;
  color: #001c41;
}
.stage {
  width: 5%;
  text-align: center;
  color: #001c41;
}

.active {
  border-radius: 20px;
  background-color: #001c41;
  color: white;
  margin-right: 10px;
  padding: 6px 16px;
  font-size: 14px;
  border: 1px solid transparent;
}

.inActive {
  font-size: 14px;
  padding: 6px 16px;
  border-radius: 20px;
  color: #001c41;
  border: 1px solid #001c41;
  margin-right: 10px;
}
