.checkbox-google {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  margin-right: 10px;
  position: relative;
  vertical-align: middle;
  font-size: 14px;
  user-select: none;
}
.checkbox-google .checkbox-google-switch {
  display: inline-block;
  width: 36px;
  height: 14px;
  border-radius: 20px;
  position: relative;
  top: 6px;
  vertical-align: top;
  background: #9f9f9f;
  transition: 0.2s;
}
.checkbox-google .checkbox-google-switch:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -3px;
  left: -1px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.15s;
}
.checkbox-google input[type='checkbox'] {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox-google input[type='checkbox']:checked + .checkbox-google-switch {
  background: #9abef7;
}
.checkbox-google
  input[type='checkbox']:checked
  + .checkbox-google-switch:before {
  background: #1a73e8;
  transform: translateX(18px);
}

/* Hover */
.checkbox-google
  input[type='checkbox']:not(:disabled)
  + .checkbox-google-switch {
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0.3);
}

/* Active/Focus */
.checkbox-google
  input[type='checkbox']:not(:disabled):active
  + .checkbox-google-switch:before,
.checkbox-google
  input[type='checkbox']:not(:disabled):focus
  + .checkbox-google-switch:before {
  animation: checkbox-active-on 0.5s forwards linear;
}
@keyframes checkbox-active-on {
  0% {
    box-shadow: 0 0 0 0 rgba(212, 212, 212, 0);
  }
  99% {
    box-shadow: 0 0 0 10px rgba(212, 212, 212, 0.5);
  }
}

.checkbox-google
  input[type='checkbox']:not(:disabled):checked:active
  + .checkbox-google-switch:before,
.checkbox-google
  input[type='checkbox']:not(:disabled):checked:focus
  + .checkbox-google-switch:before {
  animation: checkbox-active-off 0.5s forwards linear;
}
@keyframes checkbox-active-off {
  0% {
    box-shadow: 0 0 0 0 rgba(154, 190, 247, 0);
  }
  99% {
    box-shadow: 0 0 0 10px rgba(154, 190, 247, 0.5);
  }
}

/* Disabled */
.checkbox-google input[type='checkbox']:disabled + .checkbox-google-switch {
  filter: grayscale(60%);
  border-color: rgba(0, 0, 0, 0.1);
}
.checkbox-google
  input[type='checkbox']:disabled
  + .checkbox-google-switch:before {
  background: #eee;
}

.od_avatar {
  border-radius: 50%;
  object-fit: cover;
}
.hover-bold {
  font-weight: lighter;
}

.hover-bold:hover {
  transition: 0.3s;
  font-weight: bold;
}
