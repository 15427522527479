.container {
  overflow: hidden;
  position: relative;
  padding: 16px;
  border-radius: 12px;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.4) 0px 2px 8px 0px;
  margin-bottom: 16px;
}

.topBorder {
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(200, 200, 200);
}

.top {
  padding-bottom: 8px;
  display: flex;
  align-items: center;
}

.image {
  border-radius: 50%;
  width: 50px !important;
  object-fit: cover;
}

.etapa {
  margin-right: 6px;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 10px;
  border-radius: 6px;
  color: #001b70;
  background-color: rgb(0, 27, 112, 0.2);
}

.new {
  margin-top: 12px;
  font-size: 14px;
  color: rgba(96, 173, 94, 255);
}
.documentation {
  margin-top: 12px;
  font-size: 14px;
  color: rgb(220, 122, 122);
}

@media screen and (max-width: 250px) {
  .image {
    display: none;
  }
}
