.checkbox_input {
  visibility: visible !important;
}

.central_container {
  border-radius: 25px;
}

.circulo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #000;
}

.treatments_logo {
  position: absolute;
  top: 25px;
  right: -26px;
  width: 100px;
  border-radius: 25px 0px 25px 0px;
  transform: rotate(-90deg);
}

.color-1 {
  color: #fff;
  background-color: #10456d;
  border-color: #10456d;
}
.color-2 {
  color: #000;
  background-color: #e0614a;
  border-color: #e0614a;
}
.color-3 {
  color: #fff;
  background-color: #288b7e;
  border-color: #288b7e;
}
.color-4 {
  color: #000;
  background-color: #e8793a;
  border-color: #e8793a;
}
.color-5 {
  color: #fff;
  background-color: #5a468e;
  border-color: #5a468e;
}
.color-6 {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.step {
  background-color: #ccc;
  color: #001b70;
  border: none;
  font-weight: bold;
  outline: none !important;
  cursor: default;
}

.volver {
  background-color: #fff;
  color: #001b70;
  border: 1px solid #001b70;
}

.volver:hover {
  color: #001b70;
}

.scanButton {
  background-color: #f8f9fa;
  color: #001b70;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0px 0px 11px rgb(0 0 0 / 13%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.scanButtonText {
  margin: 0;
  border-radius: 15px;
  padding: 25px 0px;
  background-color: #001b70;
  color: #fff;
}

.scanButton:hover {
  cursor: default !important;
}

.avatar_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.buttonFormOnline {
  background-color: #ccc !important;
  border: none;
}

.escaneoIntraoralBTN {
  margin-top: 20px;
  width: 270px;
  height: 270px;
  padding: 20px;
  box-shadow: 0px 0px 11px rgb(0 0 0 / 13%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.escaneoIntraoralBTNSelected {
  background-color: #002541;
  color: #fff;
  margin-top: 20px;
  width: 270px;
  height: 270px;
  padding: 15px;
  box-shadow: 0px 0px 11px rgb(0 0 0 / 13%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.escaneoIntraoralBTN:hover {
  cursor: pointer;
}
.escaneoIntraoralBTNSelected:hover {
  cursor: pointer;
}

.escaneoIntraoralBTN > p {
  color: #002e50;
  text-align: center;
}

.stepsix-patientName {
  max-width: 90%;
}
