.online-form-btn {
  color: rgb(138, 185, 241);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
}

.online-form-btn:hover {
  letter-spacing: 1px;
  transition: 0.2s;
}
