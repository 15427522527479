.checkbox_input {
  visibility: visible !important;
}

.central_container {
  border-radius: 25px;
}

.p-relative {
  position: relative;
}

.circulo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #000;
}

.treatments_logo {
  position: absolute;
  top: 25px;
  right: -26px;
  width: 100px;
  border-radius: 25px 0px 25px 0px;
  transform: rotate(-90deg);
}

.color-1 {
  color: #fff;
  background-color: #10456d;
  border-color: #10456d;
}
.color-2 {
  color: #000;
  background-color: #e0614a;
  border-color: #e0614a;
}
.color-3 {
  color: #fff;
  background-color: #288b7e;
  border-color: #288b7e;
}
.color-4 {
  color: #000;
  background-color: #e8793a;
  border-color: #e8793a;
}
.color-5 {
  color: #fff;
  background-color: #5a468e;
  border-color: #5a468e;
}
.color-6 {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.step {
  background-color: #001b70;
  color: white;
  border: none;
  font-weight: bold;
  outline: none !important;
  cursor: default;
}

.volver {
  background-color: #fff;
  color: #001b70;
  border: 1px solid #001b70;
}

.volver:hover {
  color: #001b70;
}

.scanButton:hover {
  cursor: default !important;
}

.avatar_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.ulScroll {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 96%;
  min-height: 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-height: 120px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.liScroll {
  height: 30px;
  background: rgb(255, 255, 255);
  border-bottom: '1px solid #0027a8' !important;
  cursor: pointer;
}
.liScroll:hover {
  background: rgb(226, 226, 226);
}

input:disabled {
  background: #ccc;
}

.escaneoIntraoralBTN {
  margin-top: 20px;
  width: 270px;
  height: 270px;
  padding: 15px;
  box-shadow: 0px 0px 11px rgb(0 0 0 / 13%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.escaneoIntraoralBTNSelected {
  background-color: #002541;
  color: #fff;
  margin-top: 20px;
  width: 270px;
  height: 270px;
  padding: 15px;
  box-shadow: 0px 0px 11px rgb(0 0 0 / 13%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.escaneoIntraoralBTN:hover {
  cursor: pointer;
}
.escaneoIntraoralBTNSelected:hover {
  cursor: pointer;
}

.escaneoIntraoralBTN > p {
  color: #002e50;
  text-align: center;
}

.income-patient-card {
  border-radius: 20px;
  padding: 8px;
}

.income-img-card {
  width: 70px;
  height: 70px;
  border-radius: 12px;
}

.plan-plus {
  border: #002541 1px solid;
  border-radius: 100px;
}
