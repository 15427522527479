.thumb {
  height: 100%;
  object-fit: cover;
}

.imageContainer > div {
  height: 100%;
}

.imagePreview {
  height: 100%;
}
