.history-card {
  width: 360px;
  height: 300px;
  border-radius: 30px;
  background: rgb(38, 40, 65);
  background: -moz-linear-gradient(
    90deg,
    rgb(0, 37, 65) 0%,
    rgb(0, 37, 65) 60%,
    rgb(0, 75, 119) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgb(0, 37, 65) 0%,
    rgb(0, 37, 65) 60%,
    rgb(0, 75, 119) 100%
  );
  background: linear-gradient(
    90deg,
    rgb(0, 37, 65) 0%,
    rgb(0, 37, 65) 60%,
    rgb(0, 75, 119) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#262841",endColorstr="#00055d",GradientType=1);
}

.clinical-history-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.clinical-history-list .clinical-history-list-item {
  padding-left: 1em;
  position: relative;
  margin-top: 25px;
}

.clinical-history-list .clinical-history-list-item::before {
  content: '';
  left: -10px;
  top: 2px;
  display: inline-block;
  width: 15px;
  height: 15px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background: transparent;
  border: 2px solid #00155c;
  position: absolute;
}
.clinical-history-list .clinical-history-list-item::after {
  content: '';
  left: -7px;
  top: 115%;
  display: inline-block;
  width: 10px;
  height: 10px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background: #ccc;
  position: absolute;
}

.clinical-history-list .clinical-history-list-item p {
  position: relative;
  margin-top: 5px;
  margin-bottom: 0;
}

.clinical-history-list .clinical-history-list-item .title::before,
.clinical-history-list .clinical-history-list-item .subtitle::before {
  content: '';
  left: -23px;
  top: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background: #ccc;
  position: absolute;
}

.red-dot::after {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  top: 3px;
  margin-left: 5px;
  /* left: -5px; */
  background-color: red;
}

.treatment-card {
  background: rgb(38, 40, 65);
  background: -moz-linear-gradient(
    90deg,
    rgb(0, 37, 65) 0%,
    rgb(0, 37, 65) 60%,
    rgb(0, 75, 119) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgb(0, 37, 65) 0%,
    rgb(0, 37, 65) 60%,
    rgb(0, 75, 119) 100%
  );
  background: linear-gradient(
    90deg,
    rgb(0, 37, 65) 0%,
    rgb(0, 37, 65) 60%,
    rgb(0, 75, 119) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#262841",endColorstr="#00055d",GradientType=1);
}

.button {
  border-radius: 20px;
  border: 1px solid #000;
}

.button:hover {
  background-color: rgb(68, 72, 119);
  cursor: pointer;
  transition: background-color 0.3s;
}

.close_button:hover {
  cursor: pointer;
  color: #555;
}

.avatarBorder {
  border-radius: 50%;
  border: 1px solid #000;
}

.avatarIMG {
  width: 100%;
}

.editPatientAvatar {
  width: 200px;
  height: 200px;
}
