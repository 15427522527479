.paymentSelectedBTN {
  border: 1px solid #111033;
  display: flex;
  align-items: center;
  border-radius: 15px;
  padding: 5px 10px;
}

.paymentSelectedBTN:hover {
  color: #fff;
  background-color: #111033;
  cursor: pointer;
  transition: all 0.2s;
}

.titleHeader {
  font-weight: bold;
  color: #111033;
}

.closeBTN {
  margin-top: -15px;
  color: #ccc;
}

.closeBTN:hover {
  color: #aaa;
  cursor: pointer;
}

.numberOfCases {
  color: #111033;
}
.numberOfCases > p {
  font-size: 0.8rem;
  margin-bottom: 15px;
}
.numberOfCases > span {
  font-size: 3.4rem;
  font-weight: bold;
  margin-left: 15px;
}

.meansOfpayment {
  color: #111033;
  font-size: 0.8rem;
  margin-bottom: 15px;
}

.icon {
  opacity: 0.3;
}

.meansIcon:hover {
  cursor: pointer;
}

.headerList {
  display: flex;
  color: #111033;
  font-weight: bold;
  font-size: 0.7rem;
  margin: 50px 0px 10px 0px;
}

.id {
  width: 10%;
  text-align: center;
}
.patient {
  width: 20%;
  text-align: center;
  margin: 0px 5px;
}
.doctor {
  width: 18%;
  text-align: center;
  margin: 0px 15px 0px 5px;
}
.requiredAction {
  width: 20%;
}

.cardsContainer {
  max-height: 230px;
  overflow: scroll;
}

.card {
  display: flex;
  align-items: center;
  border-top: 1px solid #ccc;
  /* border-bottom: 1px solid #ccc; */
  font-size: 0.7rem;
  padding: 10px 0px;
}

.flagsFather {
  margin-left: 10px;
  width: 25%;
  display: flex;
}

.flags {
  font-size: 0.7rem;
  border: 1px solid #111033;
  border-radius: 15px;
  color: #111033;
  padding: 1px 10px;
  margin: 0px 5px;
}

.checkbox {
  margin-right: 20px;
}

.paymentButton {
  text-align: center;
  color: #fff;
  padding: 15px 0px;
  background-color: #91c4e8;
  border-radius: 50px;
  width: 17%;
  font-size: 1.3rem;
}

.paymentButton:hover {
  cursor: pointer;
}
