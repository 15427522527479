.generateReferralsButton {
  background: #002d4f;
  color: #fff;
  height: 35px;
}

.generateReferralsButton:hover {
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s;
}

.generateReferralsButton:focus {
  outline: none;
}

.headerRight {
  width: 250px;
}

.filterBTNS {
  color: #111033;
}
.filterBTNS:hover {
  cursor: pointer;
}

.tableHead {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 30px 0 10px 0;
}

.tableHead > p {
  font-size: 14px;
  font-weight: bold;
  color: #111033;
}

.allFilter {
  width: 5%;
  text-align: center;
  margin: 0px;
}

.completeName,
.email,
.phone,
.locality,
.state,
.od {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.completeName {
  width: 10%;
  text-align: center;
  margin: 0px 5px;
}
.email {
  width: 12%;
  text-align: center;
  margin: 0px 5px;
}
.phone {
  width: 10%;
  text-align: center;
  margin: 0px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.preferenceDate {
  width: 9%;
  text-align: center;
  margin: 0px 20px;
}

.locality {
  width: 10%;
  text-align: center;
  margin: 0px 5px;
}

.state {
  width: 13%;
  text-align: center;
  margin: 0px 5px;
}
.od {
  width: 10%;
  text-align: center;
  margin: 0px 0px;
}

.referral {
  width: 9%;
  text-align: center;
  margin: 0px 20px;
}

.referralCard {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 20px 1px;
  font-size: 12px;
}
.pending {
  color: #fff;
  background: #d99e31;
  padding: 7px 10px;
  border: none;
  border-radius: 5px;
  width: 10%;
  margin: 0px 25px;
}
.derivated {
  color: #fff;
  background: #35ae2a;
  padding: 7px 10px;
  border: none;
  border-radius: 5px;
  width: 10%;
  margin: 0px 25px;
}

.referralsButtonHidden {
  height: 35px;
  margin-top: 0.5rem;
}
