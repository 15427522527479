.text-green {
  color: rgb(70, 217, 70);
}
.text-red {
  color: rgb(217, 70, 70);
}
.text-yellow {
  color: #fca223;
}

.nav-btns-container {
  border-bottom: 1px solid rgb(120, 120, 168);
}

.nav-btn {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  outline: none;
  margin-top: 10px;
}

.nav-btn:focus {
  border: none;
  outline: none;
}

.image-name {
  border: 1px solid rgb(180, 180, 180);
  padding: 6px 0px;
  text-align: center;
  border-radius: 10px;
  background: white;
}

.saveBtn {
  margin: 24px 0;
  box-shadow: rgba(0, 0, 0) 0px 4px 12px;
  width: 320px;
  height: 40px;
  border-radius: 20px;
  outline: none;
  border: none;
  background-color: #33b558;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.green-proposal-btn {
  background: #25aa37;
  color: white;
  padding: 10px 100px;
  border-radius: 20px;
  cursor: pointer;
}
.red-proposal-btn {
  background: rgb(217, 70, 70);
  color: white;
  padding: 10px 100px;
  border-radius: 20px;
  cursor: pointer;
}

.PD-green {
  background: #25aa37;
}

.PD-red {
  background: rgb(217, 70, 70);
}

.AM-first-text {
  text-align: center;
  margin-bottom: 40px;
}
.RM-first-text {
  text-align: center;
  margin-bottom: 10px;
}
.RM-text {
  border-radius: 12px;
  border: 1px solid gray;
  width: 100%;
  height: 200px;
  padding: 8px;
}
.AM-second-text {
  text-align: center;
  color: gray;
  margin-bottom: 16px;
}
.AM-btn {
  padding: 8px 0;
  width: 300px;
  text-align: center;
  border-radius: 20px;
  margin-top: 12px;
  color: white;
  cursor: pointer;
}
.AM-container {
  width: 400px;
  margin-top: 10%;
}

.plan-container {
  transition: all 0.4s;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.plan-container:hover {
  scale: 1.125;
}

.inputDisplay {
  transition: all 0.3s;
}
.inputDisplay:hover {
  cursor: pointer;
  scale: 1.055;
}

.form-error-card {
  background-color: white;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.text-orange {
  color: #fca223;
}

@media (max-width: 600px) {
  .AM-container {
    width: 300px;
    margin: auto;
    margin-top: 20%;
  }
  .AM-btn {
    width: 200px;
  }
  .green-proposal-btn {
    padding: 8px 20px;
  }
  .red-proposal-btn {
    padding: 8px 15px;
  }
}
