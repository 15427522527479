.header {
  background: rgb(0, 29, 87);
  background: linear-gradient(
    90deg,
    rgba(0, 29, 87, 1) 0%,
    rgba(36, 63, 142, 1) 100%
  );
  color: #fff;
  padding: 23px 120px 64px 120px;
}

.header > img {
  width: 4.5vw;
}

.pointsDetail {
  margin: 0 auto;
  margin-top: -40px;
  z-index: 5;
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 900px;
}
.pointsDetail > p {
  margin: 0;
}

.pointsDetail > p > span {
  font-weight: bold;
}

.coin {
  width: 3vw;
}

.seePoints:hover {
  cursor: pointer;
}

.questions {
  padding: 23px 120px;
}

.questions > ul > li {
  margin: 20px 0;
}

.benefits {
  padding: 23px 110px;
}

.card {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
  margin: 0px 10px;
}

.card:hover {
  transform: scale(1.05);
  transition: all 0.5s;
}

.coinCard {
  margin: 0px auto;
  margin-top: -40px;
  width: 4vw;
}

.first {
  border-radius: 50px 0px 0px 50px;
}

.last {
  border-radius: 0px 50px 50px 0px;
}

.cardListBenefit {
  margin-top: 20px;
  margin-bottom: 80px;
  line-height: 13px;
  min-height: 280px;
  text-align: start;
}

.cardListBenefit > li {
  margin: 20px 0px;
  font-size: 15px;
}
.cardListBenefit > li > span {
  font-weight: bold;
}

.footerCard {
  align-self: end;
  line-height: 13px;
  font-size: 15px;
}

.seeDetails {
  padding: 50px 140px 0px 140px;
}

.pointList {
  padding: 50px 140px 64px 140px;
  font-size: 1.25rem;
}

.smileProgresBar {
  width: 100%;
  height: 30px;
  display: flex;
  position: relative;
}

.gold {
  background-color: #ddb802;
  color: transparent;
  border-radius: 20px 0px 0px 20px;
}
.platinum {
  background-color: #c9d6dd;
  z-index: 2;
  color: transparent;
}
.black {
  background-color: #000;
  z-index: 2;
  color: transparent;
}

.elite {
  background-color: #001d57;
  z-index: 2;
  color: transparent;
  border-radius: 0 20px 20px 0;
}

.boxFather {
  display: flex;
  position: relative;
}

.boxGold {
  background-color: #ddb802;
  color: transparent;
  width: 250px;
  height: 30px;
  position: absolute;
  left: 600px;
  z-index: 1;
}
.boxPlatinum {
  background-color: #c9d6dd;
  color: transparent;
  width: 250px;
  height: 30px;
  position: absolute;
  left: 930px;
  z-index: 1;
}
.boxBlack {
  background-color: #000;
  color: transparent;
  width: 250px;
  height: 30px;
  position: absolute;
  left: 1232px;
  z-index: 1;
}

.smileIndicator {
  display: flex;
  justify-content: end;
}

.smileIndicator > img {
  width: 30px;
}

.wd-1 {
  width: 7%; /* 5-10 */
}
.wd-2 {
  width: 16%; /* 10-15 */
}
.wd-3 {
  width: 23%; /* 15-19 */
}
.wd-4 {
  width: 29%; /* 20-25 */
}
.wd-5 {
  width: 38%; /* 25-30 */
}
.wd-6 {
  width: 46%; /* 30-35 */
}
.wd-7 {
  width: 55%; /* 35-40 */
}
.wd-8 {
  width: 64%; /* 40-45 */
}
.wd-9 {
  width: 72%; /* 45-50 */
}
.wd-10 {
  width: 80%; /* +50 */
}
.patientList {
  font-size: 1.5rem;
}
/* RESPONSIVE 1366 */
@media (min-width: 1366px) {
  .coinCard {
    top: -30px;
    left: 80px;
  }

  .smileTitle {
    font-size: 17px !important;
  }

  .cardListBenefit {
    margin-top: 10px;
  }

  .cardListBenefit > li {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
/* Mobile view */
@media screen and (max-width: 1000px) {
  .header {
    padding: 23px 30px 64px 30px;
  }
  .header > img {
    width: 20vw;
  }
  .pointsDetail {
    display: block;
    text-align: center;
    width: 90%;
    margin: -40px auto;
  }
  .coin {
    width: 6vw;
  }
  .seePoints {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .questions {
    padding: 20px;
    font-size: 15px;
  }

  .questions > ul > li {
    margin: 10px 0;
  }

  .benefits {
    padding: 20px;
  }
  .categoryCard {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  .benefitsCardContainer {
    flex-direction: column;
    margin: 0;
  }
  .card {
    width: 50% !important;
    margin: 0.5rem auto;
  }
  .first {
    border-radius: 50px 50px 0px 0px;
  }

  .last {
    border-radius: 0px 0px 50px 50px;
  }
  .coinCard {
    margin: 0px auto;
    width: 6vw;
  }
  .card:hover {
    transform: scale(1.03);
    transition: all 0.5s;
  }

  .dataContainer {
    margin-top: 20px !important;
  }
  .sectionContainer {
    margin-bottom: 50px;
  }
  .patientList {
    font-size: 0.8rem;
  }
  .pointList {
    padding: 50px 50px;
    font-size: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .card {
    width: 100% !important;
    margin: 0.5rem auto;
  }
}
