.expandBTN {
  z-index: 1;
  width: 33px;
  right: -18px;
  top: -1px;
  position: relative;
}

.expandBTNFather {
  position: relative;
  bottom: -90px;
  right: 1000px;
  border: 1px solid transparent;
}
.expandBTNFatherOd {
  position: relative;
  bottom: -90px;
  right: 1070px;
  border: 1px solid transparent;
}

.expandBTNFather:hover{
  cursor: pointer;
  /* transform: scale(1.25); */
  opacity: 0.7;
  transition: all 0.3s;
}
.expandBTNFatherOd:hover{
  cursor: pointer;
  /* transform: scale(1.25); */
  opacity: 0.7;
  transition: all 0.3s;
}
