.max-width-600-login {
  max-width: 600px;
}
.mx-300 {
  max-width: 300px;
}

.login-banner-top {
  display: none;
}
.login-banner-bottom {
  display: flex;
}
.login-container {
  display: flex;
}

.responsive-margin-top {
  margin-top: 15rem;
}

@media (max-width: 1000px) {
  .responsive-margin-top {
    margin-top: 6rem;
  }
  .login-container {
    display: block;
  }
  .login-banner-top {
    display: block;
  }
  .login-banner-bottom {
    display: none;
  }
}
