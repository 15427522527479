.RO-container {
  border: 1px solid #dbdbdb;
  border-radius: 40px;
  width: 80%;
  margin: auto;
  margin-top: 100px;
  padding: 40px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 16px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
}

.RO-patient-card {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 8px;
  padding: 25px;
  border-radius: 20px;
}

.RO-input-id {
  width: 180px;
  outline: none;
  border: none;
  border-bottom: 1px solid rgb(0, 0, 110);
  background-color: rgb(252, 252, 252);
  border-radius: 2px;
  padding-left: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.RO-input-link {
  width: 180px;
  outline: none;
  border: none;
  border-bottom: 1px solid rgb(0, 0, 110);
  background-color: rgb(240, 240, 240);
  border-radius: 2px;
  padding-left: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  height: 28px;
}

.RO-light-shadow {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 8px;
}

.RO-error {
  color: rgb(242, 41, 41);
  font-weight: bold;
}

.RO-btn {
  width: 120px;
  height: 40px;
  border-radius: 20px;
  outline: none;
  border: none;
  background-color: #58dc7d;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.RO-success-text {
  color: #3ea35a;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.RO-success-image {
  text-align: center;
}

.proposal-tag {
  padding: 8px 22px;
  background-color: rgb(220, 226, 255);
  border-radius: 10px;
  margin-right: 20px;
}

.proposal-link {
  margin-left: 160px;
}

.proposal-btn {
  margin-top: 25px;
  width: 140px;
  font-size: 14px;
  height: 40px;
  border-radius: 20px;
  outline: none;
  border: none;
  font-weight: bold;
  background: white;
  border: 1px solid rgb(140, 140, 140);
}

.type-1 {
  background-color: #10456d;
}
.type-2 {
  background-color: #288b7e;
}
.type-3 {
  background-color: #5a468e;
}
.type-4 {
  background-color: #e0614a;
}
.type-5 {
  background-color: #e8793a;
}
.type-6 {
  background-color: #ffc107;
}
