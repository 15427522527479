.tc-container {
  padding-top: 10px;
  position: relative;
}

.tc-p {
  font-size: 0.9em;
  margin-top: 5px;
  font-weight: bold;
}

.tc-small {
  font-size: 0.7em;
}

.XBtn:hover {
  cursor: pointer;
  color: #444;
}

.CH-image-container {
  border: 1px solid #ccc;
  border-radius: 20px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.black {
  color: black;
}

.type-tag {
  color: aliceblue;
  text-align: center;
  line-height: 100%;
  border-bottom-left-radius: 16px;
  border-top-right-radius: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

.text-gray {
  color: rgb(2, 2, 2, 0.3);
}

.CH-small {
  font-size: 14px;
}
.CH-stage-tag {
  border-radius: 10px;
  height: 25px;
}

.CH-select {
  border: none;
  border-bottom: #001c41 1px solid;
}

.CH-blueKS {
  background-color: rgb(220, 226, 255);
}

.CH-aprobado {
  background-color: #87c28f;
  color: #0d841d;
}

.CH-pendiente {
  border: none;
  outline: none;
  background-color: #f8e1b2;
  color: #f0bb51;
}

.CH-rechazado {
  background-color: #f8beb2;
  color: #f07751;
}

.CH-comment-icon {
  cursor: pointer;
  margin: 0px 16px;
}

.CH-space-maker {
  border-bottom: 2px solid rgb(200, 200, 200);
  height: 40px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.CH-flags-btn-blue {
  outline: none;
  border: 1px solid #10456d;
  margin: 0px 5px;
  padding: 4px 16px;
  border-radius: 20px;
  font-weight: bold;
  color: #10456d;
  background: white;
}

.disabled {
  opacity: 0.5;
}

.CH-flags-btn-red {
  outline: none;
  border: 1px solid #f53535;
  margin: 0px 5px;
  padding: 4px 16px;
  border-radius: 20px;
  font-weight: bold;
  color: white;
  background: #f53535;
}
.CH-flags-btn-red:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
}
.CH-flags-btn-blue:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
}

.CH-flags-btn-red:focus {
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
}
.CH-flags-btn-blue:focus {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
  outline: none;
}

.red {
  color: #f53535;
}

.green {
  color: rgb(70, 217, 70);
}

.blue {
  color: #10456d;
}

.modal-container {
  z-index: 1;
  border-radius: 20px;
}

.gallery {
  z-index: 200;
}

.detailProfileIMG {
  width: 65px;
  height: 65px;
  border-radius: 15%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.type-1 {
  background-color: #10456d;
}
.type-2 {
  background-color: #288b7e;
}
.type-3 {
  background-color: #5a468e;
}
.type-4 {
  background-color: #e0614a;
}
.type-5 {
  background-color: #e8793a;
}
.type-6 {
  background-color: #ffc107;
}

.treatmentStateBox {
  border-radius: 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.barItem {
  position: relative;
  top: 0px;
  right: -4px;
  margin: 0 !important;
  width: 35px;
}

.btnDocumentation:hover {
  cursor: pointer;
}

.moreVerticalBTN {
  z-index: 110;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 13px;
  right: 20px;
}
.uploadBTN {
  z-index: 110;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 18px;
  right: 18px;
  padding: 3px;
}

.inputDropDown {
  z-index: 120;
  position: absolute;
  background-color: #fff;
  border-radius: 5%;
  padding: 10px;
  top: 40px;
  right: -65px;
  width: 100px;
}
.inputDropDown > span {
  color: #10456d;
}

.inputDropDown > span:hover {
  cursor: pointer;
}

.moreVerticalBTN:hover {
  cursor: pointer;
}

.imageInputSelect {
  border-radius: 10px;
  min-height: 30px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
  border: none;
}
.imageInputText {
  text-align: center;
  line-height: 30px;
  letter-spacing: 0.5px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
  border: none;
}

.imageInputSelect:focus {
  outline: none;
}

.paginationBTN:hover {
  cursor: pointer;
}

.paginationBTNText {
  line-height: 100%;
}

.deleteBTN {
  min-width: 200px;
}

.opacityPlaceholder {
  opacity: 0.5;
}

.propsal-visor-container {
  position: relative;
}

.proposal-rech-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.2);
}
.proposal-rech-card {
  width: 80%;
  height: 50%;
  background: white;
  border-radius: 40px;
  flex-direction: column;
}

.text-rech {
  text-align: center;
}

.close-btn {
  cursor: pointer;
  color: rgb(40, 40, 40);
  text-align: end;
}

.rech-text-area {
  height: 100%;
  padding: 8px;
  margin: 24px;
  border-radius: 12px;
  background-color: white;
  border: 2px solid rgb(211, 211, 211);
}

.hidden {
  display: none;
}

.editableInputs {
  padding: 0px;
  border: none;
  width: 75px;
}

.idInput {
  width: 50%;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.editableInputs:focus {
  outline: none;
}
.editableInputs::-webkit-input-placeholder {
  font-weight: bold;
  color: #000;
}

.darDeAltaBTN {
  background-color: #001c41;
  color: #fff;
  width: 62%;
  padding: 5px 0px;
  border-radius: 15px;
}

.editRegistrationDate:hover {
  cursor: pointer;
  opacity: 0.7;
  transition: 0.2s;
}

.registrationDateInput {
  height: 19px;
  width: 85px;
  border: none;
  border-bottom: 1px solid #ccc;
}

.registrationDateInput:focus {
  outline: none;
}

.shareBTN {
  z-index: 1;
  width: 33px;
  right: -18px;
  top: -1px;
  position: relative;
}

.shareBTNFather {
  position: relative;
  bottom: -55px;
  right: 30px;
  border: 1px solid transparent;
}

.shareBTNFather:hover {
  width: 280px;
  bottom: -55px;
  height: 33px;
  color: #fff;
  transition: all 0.5s;
  background-color: transparent;
  font-weight: bold;
  cursor: pointer;
  text-align: right;
  border: 1px solid #fff;
  border-right: none;
  border-radius: 10px 0px 0px 10px;
}

.shareBTNFather:hover::before {
  content: 'Compartir visor con mi paciente';
  color: #fff;
}

.viewerInput {
  padding: 10px;
  border: 1px solid #ccc;
  color: #ccc;
  border-radius: 5px;
  background-color: #fff;
  width: 90%;
}

.viewerInput:disabled {
  background-color: #fff;
}

.copyBTN {
  width: 30%;
  text-align: center;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 15px;
  border: 1px solid #052051;
  color: #052051;
}

.copyBTN:hover {
  cursor: pointer;
  background-color: #052051;
  color: #fff;
  transition: all 0.3s;
}

.xBTN:hover {
  cursor: pointer;
  opacity: 0.7;
}

.back-btn {
  font-weight: bold;
  color: gray;
  border: 1px solid gray;
  border-radius: 30px;
  width: 120px;
  padding: 8px 0px;
  margin: 0px 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.confirmate-btn {
  font-weight: bold;
  color: #65cf73;
  border: 1px solid #65cf73;
  border-radius: 30px;
  width: 120px;
  padding: 8px 0px;
  margin: 0px 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
}
