.cardComponent {
  border: 1px solid #ccc;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin: 5px 0;
  padding: 10px 1px;
}

.cardComponent:hover {
  cursor: pointer;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #000;
  margin-right: 10px;
}

.logo {
  width: 2vw;
}

.requiredAction {
  display: flex;
  justify-content: flex-end;
  width: 37%;
  font-weight: bold;
  color: red;
}

.buttons {
  display: flex;
  justify-content: end;
  width: 20%;
}

.actionBTN {
  border: 1px solid #023e8d;
  border-radius: 15px;
  margin: 0px 5px;
  width: 90px;
  padding: 5px 0px;
  text-align: center;
  color: #023e8d;
}

.actionBTN:hover {
  background-color: #023e8d;
  color: #fff;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.patient {
  width: 60%;
}

.avatarIMG {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
