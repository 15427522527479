
  
  .shareBTN {
    z-index: 1;
    width: 33px;
    right: -18px;
    top: -1px;
    position: relative;
  }
  
  .shareBTNFather {
    position: relative;
    bottom: -55px;
    right: 30px;
    border: 1px solid transparent;
  }
  
  .shareBTNFather:hover {
    width: 280px;
    bottom: -55px;
    height: 33px;
    color: #fff;
    transition: all 0.5s;
    background-color: transparent;
    font-weight: bold;
    cursor: pointer;
    text-align: right;
    border: 1px solid #fff;
    border-right: none;
    border-radius: 10px 0px 0px 10px;
  }
  
  .shareBTNFather:hover::before {
    content: 'Compartir visor con mi paciente';
    color: #fff;
  }
  
  .viewerInput {
    padding: 10px;
    border: 1px solid #ccc;
    color: #ccc;
    border-radius: 5px;
    background-color: #fff;
    width: 90%;
  }
  
  .viewerInput:disabled {
    background-color: #fff;
  }
  
  .copyBTN {
    width: 30%;
    text-align: center;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 15px;
    border: 1px solid #052051;
    color: #052051;
  }
  
  .copyBTN:hover {
    cursor: pointer;
    background-color: #052051;
    color: #fff;
    transition: all 0.3s;
  }
  
  .xBTN:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  