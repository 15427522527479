.title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 40px;
  text-align: center;
}

.text {
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 20px;
  text-align: center;
}

.watch {
  font-size: 28px;
}

.startButton {
  color: #002e50;
  font-size: 20px;
  font-weight: bold;
}

.closeModalButton {
  color: #ccc;
}
.closeModalButton:hover {
  cursor: pointer;
  color: #aaa;
  transition: all 0.3s;
}

.closeModalButton:focus {
  outline: none;
}

.nextButton {
  background-color: #031e71;
  margin-top: 60px;
  color: #fff;
  border-radius: 15px;
  height: 100%;
  padding: 6px 40px;
  outline: none;
  border: none;
}
.nextButton:hover {
  outline: none;
  border: none;
  opacity: 0.7;
  cursor: pointer;
  scale: 1.05;
  transition: all 0.3s;
}

@media screen and (max-width: 1000px) {
  .nextButton {
    margin: 15px 0px;
  }
}
