.today-card {
  width: 100%;
  max-height: 50px;
  height: 100%;
  /* background-color: red; */
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
}

.today-card-sm {
  width: 100%;
  max-height: 50px;
  height: 100%;
  /* background-color: red; */
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
}

.tableHead {
  display: flex;
  width: 100%;
}

.idHead {
  margin-left: 25px;
  width: 60px;
}
.patientHead {
  width: 150px;
}

.doctorHead {
  width: 180px;
}

.treatmentHead {
  width: 115px;
}
.stageHead {
  width: 90px;
}
.maxilarHead {
  width: 90px;
}
.movementsHead {
  width: 120px;
}
.proposalHead {
  width: 190px;
}

.idControl {
  display: flex;
  width: 80px;
  align-items: center;
  justify-content: space-between;
  margin-left: -6px;
}

.patientControl {
  width: 150px;
  margin: 0px 6px;
}

.doctorControl {
  width: 130px;
  margin: 0px 6px;
}

.treatmentControl {
  width: 80px;
  margin: 0px 0px 0px 28px;
}
.stageControl {
  width: 15px;
  margin: 0px 20px;
}

.maxillarControl {
  width: 66px;
}

.movementsControl {
  width: 20px;
  margin: 0px 40px;
}
.treatmentProposalControl {
  width: 100px;
  margin: 0px 37px;
}

.seeBTNControl {
  color: #001b70;
  border-radius: 15px;
  border: 1px solid #001b70;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80px;
  padding: 5px;
}

.id {
  display: flex;
  width: 80px;
  align-items: center;
  margin: 0px 6px;
}

.patient {
  width: 150px;
  margin: 0px 6px;
}

.doctor {
  width: 150px;
  margin: 0px 6px;
}

.treatment {
  width: 80px;
  margin: 0px 0px 0px 28px;
}

.stage {
  width: 15px;
  margin: 0px 20px;
}

.maxillar {
  width: 66px;
}

.movements {
  width: 20px;
  margin: 0px 40px;
}
.treatmentProposal {
  width: 100px;
  margin: 0px 45px;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #000;
}
