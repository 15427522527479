.checkbox_input {
  visibility: visible !important;
}

.central_container {
  border-radius: 25px;
}

.circulo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #000;
}

.treatments_logo {
  position: absolute;
  top: 25px;
  right: -26px;
  width: 100px;
  border-radius: 25px 0px 25px 0px;
  transform: rotate(-90deg);
}

.color-1 {
  color: #fff;
  background-color: #10456d;
  border-color: #10456d;
}
.color-2 {
  color: #000;
  background-color: #e0614a;
  border-color: #e0614a;
}
.color-3 {
  color: #fff;
  background-color: #288b7e;
  border-color: #288b7e;
}
.color-4 {
  color: #000;
  background-color: #e8793a;
  border-color: #e8793a;
}
.color-5 {
  color: #fff;
  background-color: #5a468e;
  border-color: #5a468e;
}
.color-6 {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.step {
  background-color: #ccc;
  color: #001b70;
  border: none;
  font-weight: bold;
  outline: none !important;
  cursor: default;
}

.volver {
  background-color: #fff;
  color: #001b70;
  border: 1px solid #001b70;
}

.volver:hover {
  color: #001b70;
}

.scanButton:hover {
  cursor: default !important;
}
.buttonFormOnline {
  background-color: #ccc !important;
  border: none;
}
