.odHomeContainer {
  width: 100%;
  height: 100%;
}

.odHomeHeader {
  background-color: #001d57;
  border-radius: 50%/0 0 6px 6px;
}

.odHeaderBtn {
  background-color: white;
  color: #00155c;
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 16px 36px;
  margin-bottom: -20px;
  cursor: pointer;
}
.odHeaderBtnDesk {
  background-color: white;
  color: #00155c;
  border: none;
  border-radius: 15px;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  height: 60px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  transition: all 0.3s;
}

.odHeaderBtnDesk:hover {
  scale: 1.06;
  cursor: pointer;
}

.odHeaderBtn:focus {
  outline: none;
}

.odFilterActive {
  background-color: #00155c;
  border-radius: 50px;
  color: #f5f5f5;
  border: 1px solid #f5f5f5;
}
.odFilterActive:hover {
  background-color: #f5f5f5;
  border-radius: 50px;
  border: 1px solid #00155c;
  color: #00155c;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.odFilterDesactive {
  background-color: #f5f5f5;
  border-radius: 50px;
  border: 1px solid #00155c;
  color: #00155c;
}
.odFilterDesactive:hover {
  background-color: #00155c;
  border: 1px solid #f5f5f5;
  border-radius: 50px;
  color: #f5f5f5;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.patientHeaderList {
  width: 30%;
  margin-left: 30px;
  color: #00155c;
  font-weight: bold;
}

.treatmentHeaderList {
  width: 15%;
  color: #00155c;
  font-weight: bold;
}
.stepHeaderList {
  color: #00155c;
  font-weight: bold;
}
.patientDataList {
  width: 22%;
  margin-left: 20px;
  margin-right: 30px;
  color: #00155c;
}

.treatmentDataList {
  width: 14%;
  color: #00155c;
}
.stepDataList {
  width: 18%;
  color: #00155c;
}
.stepStateList {
  color: red;
}
.stepSeeList {
  width: 35%;
}

.seeBTN {
  background-color: #f5f5f5;
  border-radius: 50px;
  border: 1px solid #00155c;
  color: #00155c;
}

.seeBTN:hover {
  background-color: #00155c;
  border: 1px solid #f5f5f5;
  border-radius: 50px;
  color: #f5f5f5;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #000;
}

.profile {
  width: 3%;
  margin-left: 10px;
}

.avatarIMG {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.patientCard {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin: 10px 0 10px 0;
}

.patientCard:hover {
  cursor: pointer;
}

.smileCreatorIMG {
  width: 30px;
  margin-left: 10px;
  margin-bottom: 3px;
}

.smileCreatorIMG:hover {
  cursor: pointer;
}

.home-active {
  border-radius: 20px;
  background-color: #00155c;
  color: white;
  margin-right: 10px;
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid transparent;
}

.home-inActive {
  font-size: 14px;
  padding: 6px 16px;
  border-radius: 20px;
  color: #00155c;
  border: 1px solid #00155c;
  margin-right: 10px;
}
