.inputDocumentoIdentidad {
  border: none;
  border-bottom: 2px solid #0027a8;
  height: 35px;
  border-radius: 0 !important;
  margin-left: 10px;
  width: 93%;
}

.inputDocumentoIdentidad:focus {
  color: #212529;
  background-color: #fff;
  border-color: #8093d4;
  outline: 0;
  box-shadow: inset 0 1px 2px rgb(0 39 168 / 8%),
    0 0 0 0.25rem rgb(0 39 168 / 25%);
}
