.NT-bottom-bar {
  padding-bottom: 10px;
  border-bottom: 2px solid rgb(230, 230, 230);
}

.NT-bottom-bar-two {
  border-bottom: 1px solid rgb(161, 161, 161);
}

.NT-gray {
  color: black;
  opacity: 40%;
}

.NT-doc {
  width: 250px;
  padding-left: 50px;
}
.NT-doc-card {
  width: 250px;
  padding-left: 5px;

  display: flex;
  align-items: center;
}

.NT-mot {
  width: 320px;
  text-align: center;
}
.NT-mot-card {
  width: 320px;
  text-align: center;
}

.NT-det {
  width: 180px;
  text-align: center;
}
.NT-det-card {
  width: 180px;
  text-align: center;
}
.NT-fecha {
  width: 150px;
  text-align: center;
}
.NT-fecha-card {
  width: 150px;
  text-align: center;
}

.NT-avatar {
  width: 32px;
  height: 32px;
  margin: 0px 10px;
  border-radius: 400px;
  border: 1px solid gray;
}
.NT-circle-yellow {
  width: 20px;
  height: 20px;
  border-radius: 400px;
  background: rgb(249, 249, 1);
}
.NT-circle-green {
  width: 20px;
  height: 20px;
  border-radius: 400px;
  background: rgb(68, 201, 70);
}
.NT-doc-name {
  max-width: 170px;
}

.NT-btn-card {
  border: none;
  background-color: transparent;
  width: 165px;
  border-radius: 100px;
  border: 1px solid rgb(86, 109, 172);
  padding: 5px 0px;
  color: rgb(86, 109, 172);
}
