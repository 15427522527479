.proposal-button {
  border: initial;
  outline: initial;
  max-width: 30px;
  max-height: 30px;
  background-color: initial;
  margin-left: auto;
}

.proposal-button img {
  width: 100%;
}

.disabled {
  opacity: 0.5;
}
