.filterBTNS {
  color: #111033;
}
.filterBTNS:hover {
  cursor: pointer;
}

.title {
  color: #111033;
}

.tableHead {
  margin: 30px 0 10px 0;
}

.tableHead > p {
  font-size: 14px;
  font-weight: bold;
  color: #111033;
}

.allFilter {
  width: 5%;
  text-align: center;
  margin: 0px;
}
.id {
  width: 8%;
  text-align: center;
  margin: 0px 5px;
}
.patients {
  width: 12%;
  text-align: center;
  margin: 0px 5px;
}
.doctor {
  width: 14%;
  text-align: center;
  margin: 0px 5px;
}
.treatment {
  width: 8%;
  text-align: center;
  margin: 0px;
}
.stage {
  width: 7%;
  text-align: center;
  margin: 0px;
}
.incomeDate {
  width: 12%;
  text-align: center;
  margin: 0px;
}
.requiredAction {
  width: 17%;
  text-align: center;
  margin: 0px;
}
.lastPayment {
  width: 13%;
  text-align: center;
  margin: 0px;
}
.paymentNro {
  width: 13%;
  text-align: center;
  margin: 0px;
}
.enable {
  width: 7%;
  justify-self: flex-end;
  text-align: center;
  margin: 0px 25px 0px auto;
}

.enableButton {
  width: 6%;
  text-align: center;
  border: none;
  border-radius: 15px;
  background-color: #91c4e8;
  padding: 10px 2px;
  font-size: 14px;
  color: #fff;
  margin-left: auto;
  margin-right: 20px;
}

.paymentCard {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 5px 1px;
}

.hidden {
  display: none;
}

.paymentCard > p {
  font-size: 14px;
}
