.notificationsHeader {
  width: 100%;
  background: rgb(0, 29, 87);
  background: linear-gradient(
    90deg,
    rgba(0, 29, 87, 1) 0%,
    rgba(36, 63, 142, 1) 100%
  );
  min-height: 182px;
  display: flex;
  align-items: center;
}

.notificationCard {
  border-radius: 15px;
  border: 1px solid #ccc;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px;
}

.middleLine {
  width: 50%;
  border-right: 1px solid #ccc;
}

.circleNotification {
  border-radius: 50%;
  border: 1px solid #000;
  background-color: #fff;
  width: 48px;
  height: 48px;
  overflow: hidden;
}

.circleNotification > img {
  width: 50px;
  height: 50px;
}

.circleDisabled {
  border-radius: 50%;
  border: 1px solid #f8f9fa;
  background-color: #f8f9fa;
  min-width: 3rem;
  min-height: 3rem;
}

.medicalRecordBTN {
  border: 1px solid #003760;
  border-radius: 15px;
  width: 40%;
  background-color: #f8f9fa;
  /* min-width: 169px !important; */
}

.medicalRecordBTN:hover {
  background-color: #003760;
  color: #f8f8f8;
  transition: all 0.2s;
}

.pendingDocBTN {
  background-color: #f8f8f8;
  border: 1px solid #000;
  border-radius: 15px;
  padding: 5px 15px;
}
.pendingDocBTN:focus {
  outline: none;
}

.pendingDocBTN:hover {
  cursor: default;
}

.descriptionCard {
  font-size: 14px;
  color: #6f7071;
  line-height: 1.3;
}

.notificationTime {
  font-size: 12px;
  min-width: 70px;
}

.filterBTN {
  padding: 3px 10px;
  background-color: #f5f5f5;
  border-radius: 50px;
  border: 1px solid #00155c;
  color: #00155c;
}
.filterBTNSelected {
  padding: 3px 10px;
  background-color: #00155c;
  border: 1px solid #f5f5f5;
  border-radius: 50px;
  color: #f5f5f5;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.filterBTN:hover {
  cursor: pointer;
}
.filterBTNSelected:hover {
  cursor: pointer;
}

.notifications-mobile-container {
  max-width: 600px;
  margin: auto;
}

.notification-mobile-title-container {
  max-width: 600px;
  width: 100%;
  margin: auto;
}
.treatment-state-notifiaction-dentist {
  margin: 20px auto;
}

@media (max-width: 1000px) {
  .circleDisabled {
    display: none;
  }
  .notificationCard {
    flex-direction: column;
  }
  .middleLine {
    width: 100%;
    border: none;
  }
}

@media (max-width: 450px) {
  .treatment-state-notifiaction-dentist {
    display: none;
  }
  .circleNotification {
    margin-bottom: 10px;
  }
  .medicalRecordBTN {
    width: 100%;
  }
}
