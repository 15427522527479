.patientDataCard > img {
  width: 8vw;
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 30%) 0px 3px 8px;
}

.treatmentBTN1 {
  margin-left: 24px;
  margin-top: 13px;
  background: #10456d;
  max-height: 30px;
  color: white;
  border-radius: 0px 15px 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.treatmentBTN2 {
  margin-left: 24px;
  margin-top: 13px;
  background: #288b7e;
  max-height: 30px;
  color: white;
  border-radius: 0px 15px 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.treatmentBTN3 {
  margin-left: 24px;
  margin-top: 13px;
  background: #5a468e;
  max-height: 30px;
  color: white;
  border-radius: 0px 15px 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.treatmentBTN4 {
  margin-left: 24px;
  margin-top: 13px;
  background: #e0614a;
  max-height: 30px;
  color: white;
  border-radius: 0px 15px 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.treatmentBTN5 {
  margin-left: 24px;
  margin-top: 13px;
  background: #e8793a;
  max-height: 30px;
  color: white;
  border-radius: 0px 15px 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.treatmentBTN6 {
  margin-left: 24px;
  margin-top: 13px;
  background: #f7c05b;
  max-height: 30px;
  color: white;
  border-radius: 0px 15px 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Stages Card CSS */

.cardContainerActive {
  box-shadow: rgb(0 0 0 / 12%) 0px 3px 8px;
  color: #fff;
  background-color: #00155c;
  width: 350px;
  height: 180px;
  border-radius: 29px;
  display: flex;
  transition: all 0.2s;
  justify-content: space-between;
}

.cardContainerActive:hover {
  cursor: pointer;
}

.cardContainerInactive {
  box-shadow: rgb(0 0 0 / 12%) 0px 3px 8px;
  color: #00155c;
  background-color: #f5f5f5;
  width: 350px;
  height: 180px;
  border-radius: 29px;
  display: flex;
  transition: all 0.2s;
  justify-content: space-between;
}

.cardContainerInactive:hover {
  cursor: pointer;
}
.dateOpacity {
  opacity: 0.5;
}

.stageNumber {
  font-size: 70px;
}

.pendings-container {
  display: flex;
  flex-direction: column;
}

.cardTreatment {
  position: absolute;
  top: -13px;
  right: 0;
}

.patientDataCard {
  display: flex;
  width: 100%;
  box-shadow: rgb(0 0 0 / 12%) 0px 3px 8px;
  border-radius: 15px;
  background-color: #f5f5f5;
}

@media screen and (max-width: 1000px) {
  .cardContainerInactive {
    height: 150px;
  }
  .cardContainerActive {
    height: 150px;
  }
  .MR-title {
    margin: auto;
  }

  .cardTreatment {
    display: none;
  }
}
@media screen and (max-width: 360px) {
  .patientDataCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .treatmentBTN1 {
    margin-left: 0;
  }

  .treatmentBTN2 {
    margin-left: 0;
  }

  .treatmentBTN3 {
    margin-left: 0;
  }

  .treatmentBTN4 {
    margin-left: 0;
  }

  .treatmentBTN5 {
    margin-left: 0;
  }

  .treatmentBTN6 {
    margin-left: 0;
  }
}
