.filterBTNS {
  color: #111033;
}
.filterBTNS:hover {
  cursor: pointer;
}

.title {
  color: #111033;
}

.tableHead {
  margin: 15px 0 10px 0;
}

.tableHead > p {
  font-size: 14px;
  font-weight: bold;
  color: #111033;
}

.allFilter {
  width: 5%;
  text-align: center;
  margin: 0px;
}
.id {
  width: 4%;
  text-align: center;
  margin: 0px 5px;
}
.patients {
  width: 12%;
  text-align: center;
  margin: 0px 5px;
}
.doctor {
  width: 14%;
  text-align: center;
  margin: 0px 5px;
}
.treatment {
  width: 8%;
  text-align: center;
  margin: 0px;
}
.stage {
  width: 7%;
  text-align: center;
  margin: 0px;
}
.incomeDate {
  width: 12%;
  text-align: center;
  margin: 0px;
}
.requiredAction {
  width: 17%;
  text-align: center;
  margin: 0px;
}
.lastClaim {
  width: 13%;
  text-align: center;
  margin: 0px;
}
.claimNro {
  width: 13%;
  text-align: center;
  margin: 0px;
}
.claim {
  width: 5%;
  justify-self: flex-end;
  text-align: center;
  margin: 0px 10px 0px 0px;
}

.claimCard {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 10px 1px;
}

.hidden {
  display: none;
}

.claimCard > p {
  font-size: 14px;
}
