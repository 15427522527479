.tc-container {
  padding-top: 10px;
  position: relative;
}

/* .tc-container::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 5px;
  width: 7px;
  height: 90%;
  background-color: var(--bs-danger);
  border-radius: 10px;
} */

.tc-p {
  font-size: 0.9em;
  margin-top: 5px;
  font-weight: bold;
}

.tc-small {
  font-size: 0.7em;
}

.XBtn:hover {
  cursor: pointer;
  color: #444;
}

.CH-image-container {
  border: 1px solid #ccc;
  border-radius: 20px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.black {
  color: black;
}

.type-tag {
  color: aliceblue;
  text-align: center;
  line-height: 100%;
  border-bottom-left-radius: 16px;
  border-top-right-radius: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

.CH-small {
  font-size: 14px;
}
.CH-stage-tag {
  background-color: rgb(220, 226, 255);
  border-radius: 10px;
}

.CH-space-maker {
  border-bottom: 2px solid rgb(200, 200, 200);
  height: 40px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.CH-flags-btn-blue {
  outline: none;
  border: 1px solid #10456d;
  margin: 0px 5px;
  padding: 4px 16px;
  border-radius: 20px;
  font-weight: bold;
  color: #10456d;
  background: white;
}

.disabled {
  opacity: 0.5;
}

.CH-flags-btn-red {
  outline: none;
  border: 1px solid #f53535;
  margin: 0px 5px;
  padding: 4px 16px;
  border-radius: 20px;
  font-weight: bold;
  color: white;
  background: #f53535;
}
.CH-flags-btn-red:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
}
.CH-flags-btn-blue:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
}

.CH-flags-btn-red:focus {
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
}
.CH-flags-btn-blue:focus {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
  outline: none;
}

.red {
  color: #f53535;
}

.green {
  color: rgb(70, 217, 70);
}

.blue {
  color: #10456d;
}

.modal-container {
  border-radius: 20px;
}

.modalContainer {
  z-index: 9999;
  border-radius: 20px;
}

.detailProfileIMG {
  width: 100px;
  height: 100px;
  border-radius: 15%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.type-1 {
  background-color: #10456d;
}
.type-2 {
  background-color: #288b7e;
}
.type-3 {
  background-color: #5a468e;
}
.type-4 {
  background-color: #e0614a;
}
.type-5 {
  background-color: #e8793a;
}
.type-6 {
  background-color: #ffc107;
}

.treatmentStateBox {
  border-radius: 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.barItem {
  position: relative;
  top: -4px;
  right: 1px;
  margin: 0 !important;
}

.btnDocumentation:hover {
  cursor: pointer;
}

.moreVerticalBTN {
  z-index: 110;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 13px;
  right: 20px;
}

.inputDropDown {
  z-index: 120;
  position: absolute;
  background-color: #fff;
  border-radius: 5%;
  padding: 10px;
  top: 40px;
  right: -65px;
  width: 100px;
}
.inputDropDown > span {
  color: #10456d;
}

.inputDropDown > span:hover {
  cursor: pointer;
}

.moreVerticalBTN:hover {
  cursor: pointer;
}
