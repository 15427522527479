.alertText {
  width: 100%;
  text-align: center;
  padding: 15px;
  background-color: #f3cc98;
  color: #fb7235;
  font-weight: bold;
  font-size: 18px;
  border-radius: 15px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}

.escaneoIntraoralBTN {
  color: #002e50;
  margin: 20px;
  width: 270px;
  height: 270px;
  padding: 20px;
  box-shadow: 0px 0px 11px rgb(0 0 0 / 13%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.escaneoIntraoralBTNSelected {
  color: #002e50;
  background: #f8f9fa;
  margin: 20px;
  width: 270px;
  height: 270px;
  box-shadow: 0px 0px 11px rgb(0 0 0 / 13%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.envioDeImpresionesBTN {
  color: #002e50;
  margin: 20px;
  width: 270px;
  height: 270px;
  padding: 0px 20px 20px 20px;
  box-shadow: 0px 0px 11px rgb(0 0 0 / 13%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.envioDeImpresionesBTNSelected {
  color: #002e50;
  background: #f8f9fa;
  margin: 20px;
  width: 270px;
  height: 75px;
  box-shadow: 0px 0px 11px rgb(0 0 0 / 13%);
  border-radius: 20px;
  display: flex;
  justify-content: center;
}

.escaneoIntraoralBTN:hover {
  cursor: pointer;
}
.escaneoIntraoralBTNSelected:hover {
  cursor: pointer;
}
.envioDeImpresionesBTN:hover {
  cursor: pointer;
}
.envioDeImpresionesBTNSelected:hover {
  cursor: pointer;
}

.escaneoIntraoralBTN > p {
  color: #002e50;
  text-align: center;
}

.cardTitle {
  text-align: center;
  width: 100%;
  border-radius: 20px;
  color: #fff;
  font-size: 25px;
  background-color: #002e50;
  padding: 25px 0px;
  font-weight: bold;
  margin: 0;
}

.cardContent {
  height: 100%;
  border-radius: 20px;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.optionsBTN {
  color: #002e50;
  font-size: 1.1rem;
}

.optionsBTN:hover {
  opacity: 0.7;
}
