.button {
  text-align: center;
  border: none;
  border-radius: 20px;
  background-color: #91c4e8;
  padding: 13px 14px;
  font-size: 14px;
  color: #fff;
  margin-left: auto;
  margin-right: 16px;
}

.button:focus {
  outline: none;
}
.button:hover {
  cursor: pointer;
}

.buttonHidden {
  background-color: #c0daec;
}
.buttonHidden:hover {
  cursor: pointer;
}

.buttonContainer {
  margin-top: 7px;
  text-align: end;
}

.buttonContainer button {
  margin-right: initial;
}

.content {
  gap: 45px;
}

.title {
  font-weight: bold;
  color: #111033;
  margin: 0;
  height: 100%;
}

.closeBTN {
  margin-bottom: 15px;
  color: #ccc;
}

.closeBTN:hover {
  color: #aaa;
  cursor: pointer;
}

.infoTitle {
  font-size: 12px;
}

.infoText {
  font-weight: bold;
  color: #111033;
}
