.btn-edit {
  cursor: pointer;
}
.btn-edit:hover {
  background: rgb(230, 230, 230);
}

.bank-card {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  margin: 20px 65px;
  padding: 25px;
  border-radius: 25px;
}

.light-blue {
  color: rgb(81, 123, 172);
}

.bottom-card {
  margin: auto;
  max-width: 510px;
  padding: 16px;
  border-radius: 15px;
  border: 1px solid rgb(183, 197, 211);
  background-color: rgb(229, 242, 255);
  margin-top: 25px;
}

.card-image {
  height: 45px;
  margin-top: 20px;
}

.copy-icon {
  cursor: pointer;
  height: 12px;
  margin-left: 4px;
  transition: 0.2s;
}

.copy-icon:hover {
  height: 14px;
  transition: 0.2;
}
