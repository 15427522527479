.pdfPaginator {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdfPaginator > p {
  margin: 0px 10px;
}

.pdfPaginator > button {
  background-color: transparent;
  border: none;
  color: #001d57;
  border-radius: 10px;
}

.pdfPaginator > button:hover {
  background-color: #001d57;
  color: #fff;
  transition: all 0.5s ease;
}

.pdfPaginator > button:focus {
  outline: none;
}

.littlePDF:hover {
  cursor: pointer;
  opacity: 0.5;
}
