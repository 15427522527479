.title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 40px;
  text-align: center;
}

.text {
  padding: 40px 30px 10px 30px;
  font-size: 20px;
  text-align: center;
}

.icon {
  font-size: 28px;
}

.button {
  background-color: #031e71;
  color: #fff;
  font-size: 20px;
  padding: 5px 35px;
  margin: 20px 0px;
  border-radius: 5px;
}

.button:hover {
  cursor: pointer;
  opacity: 0.7;
  scale: 1.05;
  transition: all 0.3s;
}
