.stepsFather {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 10px 40px 10px;
}
.currentStep {
  background-color: #002e50;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  margin: 0px 7px;
  color: transparent;
}
.step {
  background-color: #b2abab;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  margin: 0px 7px;
  color: transparent;
}
.step:hover {
  cursor: pointer;
}
