.claimBTN {
  width: 6%;
  text-align: center;
  border: none;
  border-radius: 15px;
  background-color: #91c4e8;
  padding: 10px 2px;
  font-size: 14px;
  color: #fff;
}

.claimBTNHidden {
  width: 6%;
  text-align: center;
  border: none;
  border-radius: 15px;
  background-color: #c0daec;
  padding: 10px 2px;
  font-size: 14px;
  color: #fff;
}

.claimModalBTN {
  border: none;
  border-radius: 55px;
  background-color: #91c4e8;
  font-size: 18px;
  color: #fff;
  padding: 10px 20px;
}

.claimModalBTN:focus {
  outline: none;
}
.claimBTN:focus {
  outline: none;
}

.title {
  font-weight: bold;
  color: #111033;
  margin: 0;
  height: 100%;
}

.closeBTN {
  margin-bottom: 15px;
  color: #ccc;
}

.closeBTN:hover {
  color: #aaa;
  cursor: pointer;
}

.infoTitle {
  font-size: 12px;
}

.infoText {
  font-weight: bold;
  color: #111033;
}

.hidden {
  width: 6%;
  padding: 10px 2px;
  display: none;
}

.cardContainer {
  padding: 16px;
  border: 1px solid rgb(199, 199, 199);
  border-radius: 20px;
}

.redTitle {
  margin: 0;
  font-weight: Bold;
  color: red;
  margin-bottom: 16px;
}

.flagsTag {
  margin-right: 30px;
  border: 1px solid gray;
  border-radius: 20px;
  width: 90px;
  text-align: center;
  padding: 4px 0px;
}

.redFlagsTag {
  background-color: rgb(255, 53, 53);
  color: white;
  margin-right: 30px;
  border-radius: 20px;
  width: 90px;
  text-align: center;
  padding: 4px 0px;
}

.claimsContainer {
  border-top: 1px solid gray;
  max-height: 230px;
  overflow: auto;
}

.claimHistoryCardContainer {
  border-bottom: 1px solid gray;
  padding: 6px;
}
