.headerList {
  display: flex;
  color: #111033;
  font-weight: bold;
  font-size: 0.7rem;
  margin: 50px 0px 10px 0px;
  align-items: center;
}

.fullName,
.email,
.phone,
.locality {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fullName {
  width: 15%;
  text-align: center;
  margin: 0px 5px;
}
.email {
  width: 20%;
  text-align: center;
  margin: 0px 5px;
}
.phone {
  width: 15%;
  text-align: center;
  margin: 0px 5px;
}
.preferenceDate {
  width: 11%;
  text-align: center;
  margin: 0px 20px;
}
.locality {
  width: 15%;
  text-align: center;
  margin: 0px 5px;
}

.referralBTN {
  text-align: center;
  color: #fff;
  padding: 10px 0px;
  background-color: #012c4c;
  border-radius: 50px;
  width: 40%;
  font-size: 1.3rem;
  border: none;
  outline: none;
}
.referralBTN:focus {
  outline: none;
}
.referralBTN:hover {
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s;
}

.checkbox {
  margin-left: 30px;
}

.cardsContainer {
  max-height: 230px;
  overflow: scroll;
}

.card {
  display: flex;
  align-items: center;
  border-top: 1px solid #ccc;
  /* border-bottom: 1px solid #ccc; */
  font-size: 0.7rem;
  padding: 10px 0px;
}

.closeBTN {
  margin-top: -15px;
  color: #ccc;
}

.closeBTN:hover {
  opacity: 0.78;
  cursor: pointer;
  transition: all 0.3s;
}
.ulScroll {
  position: absolute;
  margin: 0;
  padding: 0;
  min-height: 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-height: 120px;
  width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.liScroll {
  height: 30px;
  background: rgb(255, 255, 255);
  border-bottom: 1px solid #0027a8 !important;
  cursor: pointer;
}
.liScroll:hover {
  background: rgb(226, 226, 226) !important;
}
.searchbar {
  width: 300px;
}
.officeAddress {
  width: 100px;
}
