.patientHistory.type-1:hover {
  width: 145px;
  transition: width 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #10456d;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.patientHistory.type-1:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.patientHistory.type-1 {
  background-color: #10456d !important;
  color: #10456d !important;
  height: 46px;
  z-index: 10;
  border-radius: 10px 0 0 10px;
  position: absolute;
  max-height: 100px;
  width: 15px;
}

.patientHistory.type-2:hover {
  width: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #288b7e;
  font-weight: bold;
  cursor: pointer;
  border-radius: 10px 10px 10px 10px;
}
.patientHistory.type-2:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.patientHistory.type-2 {
  background-color: #288b7e !important;
  color: #288b7e !important;
  height: 46px;
  z-index: 10;
  border-radius: 10px 0 0 10px;
  position: absolute;
  max-height: 100px;
  width: 15px;
}

.patientHistory.type-3:hover {
  width: 145px;
  transition: width 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #5a468e;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.patientHistory.type-3:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.patientHistory.type-3 {
  background-color: #5a468e !important;
  color: #5a468e !important;
  height: 46px;
  z-index: 10;
  border-radius: 10px 0 0 10px;
  position: absolute;
  max-height: 100px;
  width: 15px;
}

.patientHistory.type-4:hover {
  width: 145px;
  transition: width 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #e0614a;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.patientHistory.type-4:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.patientHistory.type-4 {
  background-color: #e0614a !important;
  color: #e0614a !important;
  height: 46px;
  z-index: 10;
  border-radius: 10px 0 0 10px;
  position: absolute;
  max-height: 100px;
  width: 15px;
}

.patientHistory.type-5:hover {
  width: 145px;
  transition: width 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #e8793a;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.patientHistory.type-5:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.patientHistory.type-5 {
  background-color: #e8793a !important;
  color: #e8793a !important;
  height: 46px;
  z-index: 10;
  border-radius: 10px 0 0 10px;
  position: absolute;
  max-height: 100px;
  width: 15px;
}

.patientHistory.type-6:hover {
  width: 145px;
  transition: width 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #f7c05b;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.patientHistory.type-6:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.patientHistory.type-6 {
  background-color: #f7c05b !important;
  color: #f7c05b !important;
  height: 46px;
  z-index: 10;
  border-radius: 10px 0 0 10px;
  position: absolute;
  max-height: 100px;
  width: 15px;
}

.patientHistory.type-7:hover {
  width: 145px;
  transition: width 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #10456d;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.patientHistory.type-7:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.patientHistory.type-7 {
  background-color: #10456d !important;
  color: #10456d !important;
  height: 46px;
  z-index: 10;
  border-radius: 10px 0 0 10px;
  position: absolute;
  max-height: 100px;
  width: 15px;
}

.stepSeeList {
  display: flex;
  justify-content: center;
  width: 33%;
}

.seeBTNControl {
  color: #001b70;
  border-radius: 15px;
  border: 1px solid #001b70;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  max-width: 80px;
}

.publicateBTNControl {
  background-color: #001b70;
  color: #fff;
  border-radius: 15px;
  border: 1px solid #001b70;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  max-width: 80px;
}
.dispatchBTN {
  background-color: #001b70;
  color: #fff;
  border-radius: 15px;
  border: 1px solid #001b70;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  min-width: 80px;
  max-width: 130px;
}

.dispatchBTN:hover {
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.dispatchFather {
  display: flex;
  width: 33%;
}
.publicateBTNControl:hover {
  cursor: pointer;
}

.seeBTNControl:hover {
  cursor: pointer;
}
