.drop-down {
  right: 0px;
  top: 40px;
  padding: 10px 0px;
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #072462;
  z-index: 11;
}

.sidebar-flag {
  width: 30px;
  height: 20px;
  margin-left: 30px;
}

.nav-arrow-down {
  transition: all 0.4s;
  margin-left: 8px;
}
.nav-up {
  rotate: 180deg;
}

.nav-right {
  line-height: 100%;
}

.navbar {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.nav-bell {
  margin-right: 24px;
}

@media (max-width: 1000px) {
  .desktop {
    display: none;
  }

  .nav-bell {
    margin-right: 2px;
    padding-top: 2px;
  }

  .drop-down {
    left: -95px;
  }
}
