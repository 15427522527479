.smile_image {
  max-width: 5rem;
  margin-bottom: 20px;
}

.title_container {
  width: 100vw;
  color: #fff;
  background: rgb(0, 29, 87);
  background: linear-gradient(
    90deg,
    rgba(0, 29, 87, 1) 0%,
    rgba(36, 63, 142, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#262841",endColorstr="#00055d",GradientType=1);
}

.inputFather {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  width: 100px;
  height: 100px;
}

.avatar_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.viabilityBTN {
  opacity: 0.5;
}

.viabilityBTN:hover {
  cursor: not-allowed;
}

.solicitudCard {
  padding-top: 1.8 rem !important;
}
