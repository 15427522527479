.full {
  border-top-left-radius: 12px;
  font-size: 12px;
  top: 13px;
  right: -13px;
  padding: 5px 10px;
  rotate: 270deg;
  position: absolute;
  color: white;
  background-color: #001b70;
}

.fast {
  border-top-left-radius: 12px;
  font-size: 12px;
  top: 13px;
  right: -13px;
  padding: 5px 10px;
  rotate: 270deg;
  position: absolute;
  color: white;
  background-color: #288b7e;
}

.medium {
  border-top-left-radius: 12px;
  font-size: 12px;
  top: 13px;
  right: -13px;
  padding: 5px 10px;
  rotate: 270deg;
  position: absolute;
  color: white;
  background-color: #5a468e;
}

.kids {
  border-top-left-radius: 12px;
  font-size: 12px;
  top: 13px;
  right: -13px;
  padding: 5px 10px;
  rotate: 270deg;
  position: absolute;
  color: white;
  background-color: #e0614a;
}

.kdFast {
  border-top-left-radius: 12px;
  font-size: 10px;
  top: 23px;
  right: -23px;
  padding: 6px 10px;
  rotate: 270deg;
  position: absolute;
  color: white;
  background-color: #e8793a;
}

.teen {
  border-top-left-radius: 12px;
  font-size: 12px;
  top: 14px;
  right: -14px;
  padding: 5px 10px;
  rotate: 270deg;
  position: absolute;
  color: white;
  background-color: #ffc107;
}

.empty {
  border-top-left-radius: 12px;
  font-size: 12px;
  top: 10px;
  right: -12px;
  padding: 5px 20px;
  rotate: 270deg;
  position: absolute;
  color: #001b70;
  border: 1px solid #001b70;
}
