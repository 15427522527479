.card {
  margin-top: 30px;
  display: flex;
  width: 40%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 30%) 6px 5px 7px -2px;
  padding: 40px;
  max-width: 550px;
  min-width: 450px;
}

.icon {
  width: 90%;
}

.button {
  margin-top: 20px;
  background-color: #0079b6;
  text-align: center;
  color: #fff;
  padding: 15px 13px;
  border-radius: 25px;
}

.button:hover {
  cursor: pointer;
  opacity: 0.7;
  scale: 1.05;
  transition: all 0.3s;
}
