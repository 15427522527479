.home-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    z-index: 1;
    background: rgb(0,21,92);
    background: linear-gradient(90deg, rgba(0,21,92,1) 0%, rgba(9,9,121,1) 50%, rgba(0,212,255,1) 100%);
}

.consults_scroll_remove::-webkit-scrollbar {
    display: none;
}

.consults_scroll_remove {
    -ms-overflow-style: none;
    scrollbar-width: none;
}