.title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}

.mainContainer {
  display: flex;
}

.text {
  font-size: 20px;
  text-align: left;
  margin-bottom: 20px;
  width: 86%;
}
.listTitle {
  font-size: 20px;
  text-align: left;
  width: 86%;
}

.listItem {
  display: flex;
  align-items: center;
}

.listItem > div {
  background-color: #002e50;
  border-radius: 100%;
  width: 13px;
  height: 13px;
  color: transparent;
  margin-right: 3px;
}

.listItem > p {
  font-size: 20px;
  margin: 0;
}

.quote {
  margin-top: 60px;
  width: 60%;
}

.videoSquare {
  margin-top: 40px;
  border: 1px solid #000;
  width: 50%;
  color: transparent;
}

.nextButton {
  background-color: #031e71;
  margin-top: 60px;
  color: #fff;
  border-radius: 15px;
  height: 100%;
  padding: 6px 40px;
}

.nextButton:hover {
  opacity: 0.7;
  cursor: pointer;
  scale: 1.05;
  transition: all 0.3s;
}

.stepTwoContainer {
  display: flex;
}

.footer {
  display: flex;
  justify-content: space-between;
}
.footerStep3 {
  display: flex;
  justify-content: end;
}

.textContainer {
  width: 50%;
}

@media screen and (max-width: 1000px) {
  .title {
    text-align: center;
  }

  .mainContainer {
    flex-direction: column-reverse;
    text-align: center;
  }

  .videoSquare {
    height: 230px;
    width: 100%;
  }
  .textContainer {
    width: 100%;
    margin-bottom: 20px;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .listTitle {
    text-align: center;
  }
  .text {
    text-align: center;
  }
  .footer {
    flex-direction: column;
    align-items: center;
  }
  .quote {
    font-size: 14px;
    margin: 15px 0px;
    width: 70%;
    text-align: center;
  }
  .nextButton {
    margin: 15px 0px;
  }
  .footerStep3 {
    display: flex;
    justify-content: center;
  }
}
