.progress {
  width: 150px;
  height: 150px;
  background: none;
  position: relative;
}

.progress::after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
}

.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 6px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.progress .progress-value {
  position: absolute;
  top: 0;
  left: 0;
}

.sidebar-drop {
  display: none !important;
}

/* .sidebar-drop:hover {
  display: flex !important;
  flex-direction: column !important;
} */

.sidebar-drop-toggle.toggled > .sidebar-drop {
  display: flex !important;
  flex-direction: column !important;
}

.dropDownFather {
  background-color: #fff;
  z-index: 1000;
  border-radius: 20px 20px;
  padding: 15px 10px;
  margin-top: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.dropDownItem:hover {
  cursor: pointer;
  font-size: bold;
}

.dropDownBtn {
  background-color: transparent;
  border: none;
  padding: 5px 0 0 0;
}

.dropDownBtn:focus {
  outline: none;
}

.patientConfirmationModalCard {
  box-shadow: rgba(0, 0, 0, 0.1) 2px 4px 12px;
  border-radius: 15px 15px;
  padding: 15px;
  border: none !important;
  display: flex;
  justify-content: space-between;
}
.patientCardData {
  min-width: 11vw;
}
.patientCardNames {
  display: flex;
  justify-content: space-between;
}
.cardImage {
  border-radius: 15px;
  width: 3vw;
}

.doctorNames {
  font-size: 12px;
}

.treatmentCardConfirmation1 {
  font-size: 10px;
  min-width: 50px;
  padding: 7px 10px;
  color: #fff;
  background-color: #001b70;
  border-radius: 0px 15px 0px 15px;
}
.treatmentCardConfirmation2 {
  font-size: 10px;
  min-width: 50px;
  padding: 7px 10px;
  color: #fff;
  background-color: #288b7e;
  border-radius: 0px 15px 0px 15px;
}
.treatmentCardConfirmation3 {
  font-size: 10px;
  min-width: 50px;
  padding: 7px 10px;
  color: #fff;
  background-color: #5a468e;
  border-radius: 0px 15px 0px 15px;
}
.treatmentCardConfirmation4 {
  font-size: 10px;
  min-width: 50px;
  padding: 7px 10px;
  color: #fff;
  background-color: #e47662;
  border-radius: 0px 15px 0px 15px;
}
.treatmentCardConfirmation5 {
  font-size: 10px;
  min-width: 50px;
  padding: 7px 10px;
  color: #fff;
  background-color: #e8793a;
  border-radius: 0px 15px 0px 15px;
}
.treatmentCardConfirmation6 {
  font-size: 10px;
  min-width: 50px;
  padding: 7px 10px;
  color: #fff;
  background-color: #f7c05b;
  border-radius: 0px 15px 0px 15px;
}

.stageBTN {
  background-color: #ccc;
  color: #001b70;
  border: none;
  font-size: 12px;
  margin-left: 7px;
  padding: 3px 10px;
  text-align: center;
  font-weight: bold;
  outline: none !important;
}

.sidebarBTN {
  position: absolute;
  margin-left: -47px;
  margin-top: 16px;
}
.sidebarBTNcollapsed {
  position: absolute;
  margin-left: -10px;
  margin-top: 27px;
}

.rotate-180 {
  rotate: 180deg;
}

.tr-500 {
  transition: all 0.5s;
}

.shadow-search {
  box-shadow: rgba(0, 0, 0, 0.18) 0px 3px 8px;
}
