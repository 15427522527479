.directionFather {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 1.5rem;
  padding-bottom: 0;
  border-left: 2px solid #0027a8;
}

.directionInput {
  width: 95%;
  border: 2px solid #edeeef;
  height: 35px;
  font-weight: bold;
  font-size: 18px;
}

.directionInput:focus {
  outline: none;
}

.directionInputBTNS {
  display: flex;
  align-items: center;
  height: 35px;
  padding-left: 15px;
}

.BTN {
  color: #0027a8;
}

.BTN:hover {
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.2s ease-in-out;
}

.searchBox {
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-top: 20px;
  width: 100%;
  border: 2px solid #edeeef;
  border-bottom: 2px solid #0027a8;
  height: 35px;
}

.searchBox > input {
  width: 96%;
  border: none;
}
.searchBox > input:focus {
  outline: none;
}

.save {
  align-self: end;
  background-color: #08c56d;
  color: #fff;
  width: 18%;
  height: 35px;
  border: none;
  font-weight: bold;
  font-size: 20px;
  border-radius: 25px;
}

.save:hover {
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.2s ease-in-out;
}
.saveDisabled {
  align-self: end;
  background-color: #08c56d;
  color: #fff;
  width: 18%;
  height: 35px;
  border: none;
  font-weight: bold;
  font-size: 20px;
  border-radius: 25px;
  opacity: 0.5;
}

.saveDisabled:hover {
  cursor: not-allowed;
}

.save:focus {
  outline: none;
}

.street {
  width: 50%;
}
.streetNumber {
  margin-left: 12px;
  width: 15%;
}
.floor {
  margin-left: 13px;
  width: 15%;
}
.apartment {
  margin-left: 13px;
  width: 15%;
}
.hood {
  width: 70%;
}
.cp {
  width: 25%;
}
.province {
  width: 33%;
}
.city {
  margin-left: 12px;
  margin-right: 12px;
  width: 33%;
}
.country {
  width: 33%;
}

.directionData > div > input {
  border: 2px solid #edeeef;
  height: 35px;
  border-bottom: 2px solid #0027a8;
}

.directionData > div > input:focus {
  outline: none;
}

.addDirection {
  background-color: transparent;
  color: #0027a8;
  width: 30%;
  margin-top: 20px;
  height: 35px;
  border: 1px solid #0027a8;
  font-size: 20px;
  border-radius: 25px;
}

.addDirection:hover {
  opacity: 0.7;
  transition: all 0.2s;
}
.addDirection:focus {
  outline: none;
}
