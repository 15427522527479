.priceCard {
  border-radius: 15px 0px 0px 15px;
}
.priceItem {
  border-top: 1px solid #ccc;
}

.infoCard {
  border-radius: 20px 20px 20px 20px;
  border: 1px solid;
}
.infoCardCol {
  border-radius: 20px 20px 20px 20px;
  border: 1px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inputPriceList {
  width: 100px;
  outline: none;
  color: #000;
  font-weight: bold;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ccc;
  background-color: #f8f9fa;
  caret-color: #ccc;
  padding-top: 0px;
}

.inputPriceList::-webkit-input-placeholder {
  text-align: center;
  font-weight: normal;
}

.calendarIcon {
  color: #0027a8;
  cursor: pointer;
  margin-left: 10px !important;
}

.saveButton {
  padding: 12px 21px !important;
}

.calendar {
  position: absolute !important;
  box-shadow: 0px 0px 15px;
  z-index: 1000;
}

.calendarFather {
  position: relative;
  width: 20rem;
}
