.grow_hover .more-btn {
  position: absolute;
  right: 10px;
  bottom: 0;
  width: 20px;
}

.generalModal {
  width: 830px !important;
}

.treatments_card_container {
  position: relative;
  z-index: 2;
}

.treatments_card_container .treatments_card_subcard {
  display: none;
}

.treatments_card_container.opened .treatments_card_subcard {
  display: flex;
  width: 100%;
  background-color: #444;
  border: 1px solid red;
  position: relative;
  top: -10px;
  height: 75px;
  z-index: 1;
}

.x_button:hover {
  cursor: pointer;
  color: #444;
}

.trash:hover {
  color: red;
}

.checkbox_input {
  visibility: visible !important;
}

.edit:hover {
  color: green;
}

.dropdown_buttons {
  z-index: 9999;
}

.treatment_button {
  border-radius: 20px 0px 20px 0px;
  width: 13%;
  opacity: 0.5;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  text-align: center;
}
.treatment_button_active {
  text-align: center;
  border-radius: 20px 0px 20px 0px;
  width: 13%;
  opacity: 1;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.step_active {
  background-color: #001b70;
  color: #fff;
  width: 12%;
  border: none;
  font-weight: bold;
  outline: none !important;
}

.step_inactive {
  background-color: #ccc;
  color: #001b70;
  width: 12%;
  border: none;
  font-weight: bold;
  outline: none !important;
}

.input_button:hover {
  color: #444;
  cursor: pointer;
}
.btnAdd {
  margin-left: 10px;
  border: 1px solid #001b70;
  color: #001b70;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
