.consult-header-container {
  background: rgb(0, 29, 87);
  background: linear-gradient(
    90deg,
    rgba(0, 29, 87, 1) 0%,
    rgba(36, 63, 142, 1) 100%
  );
  color: white;
}

.consult-card-container {
  background-color: white;
  width: 200px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.consult-card-container:hover {
  transform: scale(1.05);
  transition: all 0.5s;
}

.consult-send-btn {
  font-weight: 600;
  font-size: 18;
  background-color: #005b9f;
  color: aliceblue;
  padding: 10px;
  border-radius: 20px;
  text-align: center;
  width: 80%;
  cursor: pointer;
  margin: 0 auto;
}
.consult-send-btn:hover {
  color: aliceblue;
  box-shadow: rgba(99, 99, 99, 0.4) 0px 2px 8px 0px;
}
