
#od_profile_pic_input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

#od_profile_pic_input + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: #fff;
    display: inline-block;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#od_profile_pic_input:focus + label,
#od_profile_pic_input + label:hover {
    background-color: #f8f9fe;
}