.hc-order-btn:hover {
    width: 250px;
    -webkit-transition: width .3s;
    transition: width .3s;
    color: #fff;
    background-color: #fff;
    font-weight: bold;
  }
  /* .hc-order-btn:hover::after {
    content: 'Ver historia clinica';
    color: #fff;
  } */

  .hc-order-btn:hover span, .hc-order-btn:hover svg {
      color: #10456D;
  }

  .hc-order-btn {
    /* background-color: #10456D!important; */
    color: #10456D!important;
    /* height: 85%; */
    /* max-height: 100px; */
    height: 35px;
    width: 35px;
    overflow: hidden;
  }

  .hc-order-btn span {
      display: none;
  }

  .hc-order-btn:hover span {
      display: flex;
      margin-top: 3px;
  }