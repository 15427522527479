.paymentHistoryType-1:hover {
  width: 145px;
  transition: width 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #10456d;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.paymentHistoryType-1:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.paymentHistoryType-1 {
  background-color: #10456d !important;
  color: #10456d !important;
  height: 46px;
  z-index: 10;
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  max-height: 100px;
  width: 10px;
}

.paymentHistoryType-2:hover {
  width: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 0.2s;
  color: #fff;
  background-color: #288b7e;
  font-weight: bold;
  cursor: pointer;
  border-radius: 10px 10px 10px 10px;
}
.paymentHistoryType-2:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.paymentHistoryType-2 {
  background-color: #288b7e !important;
  color: #288b7e !important;
  height: 46px;
  z-index: 10;
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  max-height: 100px;
  width: 10px;
}

.paymentHistoryType-3:hover {
  width: 145px;
  transition: width 0.2s;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5a468e;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.paymentHistoryType-3:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.paymentHistoryType-3 {
  background-color: #5a468e !important;
  color: #5a468e !important;
  height: 46px;
  z-index: 10;
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  max-height: 100px;
  width: 10px;
}

.paymentHistoryType-4:hover {
  width: 145px;
  transition: width 0.2s;
  color: #fff;
  background-color: #e0614a;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.paymentHistoryType-4:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.paymentHistoryType-4 {
  background-color: #e0614a !important;
  color: #e0614a !important;
  height: 46px;
  z-index: 10;
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  max-height: 100px;
  width: 10px;
}

.paymentHistoryType-5:hover {
  width: 145px;
  transition: width 0.2s;
  color: #fff;
  background-color: #e8793a;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.paymentHistoryType-5:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.paymentHistoryType-5 {
  background-color: #e8793a !important;
  color: #e8793a !important;
  height: 46px;
  z-index: 10;
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  max-height: 100px;
  width: 10px;
}

.paymentHistoryType-6:hover {
  width: 145px;
  transition: width 0.2s;
  color: #fff;
  background-color: #f7c05b;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.paymentHistoryType-6:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.paymentHistoryType-6 {
  background-color: #f7c05b !important;
  color: #f7c05b !important;
  height: 46px;
  z-index: 10;
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  max-height: 100px;
  width: 10px;
}
