.form-online-container {
  width: 100%;
  height: 100%;
  margin: auto;
  display: 95;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
}

.moreVertical {
  z-index: 110;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #10456d;
  border-radius: 100%;
}

.moreVertical:hover {
  cursor: pointer;
}

.dropdown-father {
  position: relative;
}

.form-online-dropdown {
  z-index: 120;
  position: absolute;
  top: 30px;
  right: 10px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  width: 100px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
}

.form-online-dropdown > p:hover {
  cursor: pointer;
}

.header-patient-data {
  width: 75%;
}

.form-online-hr {
  width: 96%;
  height: 2px;
}

.step-title {
  width: 22%;
}

.title-sub-bar {
  width: 50px;
  height: 5px;
  background-color: #10456d;
  left: -150px;
  bottom: -2px;
}

.step-text-area {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  width: 71%;
  margin-top: 25px;
  min-height: 100px;
  min-width: 300px;
}

.diagnosis-container {
  width: 71%;
}

.steps-diagnosis-container {
  margin-top: 25px;
}
.step-movements-text-area {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  margin-top: 25px;
  width: 100%;
  min-height: 100px;
  min-width: 300px;
}

.text-stepper {
  color: gray;
  font-size: 0.875em;
  font-weight: 600;
}

.progressBarLine {
  background-color: #10456d;
  width: 3px;
  height: 105px;
  top: 5px;
  right: 6px;
  position: absolute;
}
.progressBarLineTreatmentIndications {
  background-color: #10456d;
  width: 3px;
  height: 285px;
  top: 5px;
  right: 6px;
  position: absolute;
}

.middleLineInputs {
  width: 50px;
}

.middleLineInputs:focus {
  outline: none;
}

.improve-overjet-container {
  width: 95%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 20px;
}

.improve-overjet-title {
  font-size: 14px;
  margin: 0;
}

.step-text-area-overjet {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  margin-top: 25px;
  max-height: 80px;
  max-width: 300px;
  min-height: 80px;
  min-width: 300px;
  overflow: hidden;
}

.extraDocumentationIMG:hover {
  cursor: pointer;
}

.inputColor::after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: transparent;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #10456d;
}

.inputColor:checked::after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #10456d;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #10456d;
}
