.containerInformation {
  background-color: #f5f5f5;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 23px;
  margin-top: 20px;
  border-radius: 35px;
}
.containerInformationMob {
  background-color: #f5f5f5;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
  padding: 20px;
  margin-top: 20px;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  align-items: center;
}

.patientData {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.information-patient {
  height: 70px;
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pointer {
  cursor: pointer;
}

.cardImageInformation {
  width: 75px;
  border: 2px solid #ccc;
  border-radius: 15px;
  object-fit: cover;
}

.treatmentCardInformation1 {
  font-size: 16px;
  min-width: 50px;
  padding: 4px 10px;
  text-align: center;
  margin-left: 40px;
  color: #fff;
  background-color: #001b70;
  border-radius: 0px 15px 0px 15px;
}
.treatmentCardInformation2 {
  font-size: 16px;
  min-width: 50px;
  padding: 4px 10px;
  text-align: center;
  margin-left: 40px;
  color: #fff;
  background-color: #288b7e;
  border-radius: 0px 15px 0px 15px;
}
.treatmentCardInformation3 {
  font-size: 16px;
  min-width: 50px;
  padding: 4px 10px;
  text-align: center;
  margin-left: 40px;
  color: #fff;
  background-color: #5a468e;
  border-radius: 0px 15px 0px 15px;
}
.treatmentCardInformation4 {
  font-size: 16px;
  min-width: 50px;
  padding: 4px 10px;
  text-align: center;
  margin-left: 40px;
  color: #fff;
  background-color: #e47662;
  border-radius: 0px 15px 0px 15px;
}
.treatmentCardInformation5 {
  font-size: 16px;
  min-width: 50px;
  padding: 4px 10px;
  text-align: center;
  margin-left: 40px;
  color: #fff;
  background-color: #e8793a;
  border-radius: 0px 15px 0px 15px;
}
.treatmentCardInformation6 {
  font-size: 16px;
  min-width: 50px;
  padding: 4px 10px;
  text-align: center;
  margin-left: 40px;
  color: #fff;
  background-color: #f7c05b;
  border-radius: 0px 15px 0px 15px;
}

.stageBTN {
  background-color: #ccc;
  color: #001b70;
  border: none;
  font-size: 12px;
  padding: 3px 10px;
  text-align: center;
  font-weight: bold;
  outline: none !important;
}

.treatmentStateBox {
  width: 380px;
  border-radius: 20px 20px;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  background-color: #fff;
}

.ul-progress-bar {
  padding: 10px 10px 10px 10px;
}

.pagination-container {
  padding-bottom: 30px;
  border-bottom: 1px solid rgb(180, 180, 180);
}

@media (max-width: 450px) {
  .treatmentStateBox {
    display: none;
  }
}
