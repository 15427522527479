.consult_item::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 2px;
    top: 6px;
    left: -30px;
    background-color: #ccc;
}

.consult_item::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 30px;
    top: -22px;
    left: -30px;
    background-color: #ccc;
}